import { faClose, faGripLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import MessageContext from "../contexts/MessageContext";

const ImageDropZone = ({ onImageDataChange, images }) => {
  const messageContext = useContext(MessageContext);
  const [imageData, setImageData] = useState(images && images.length > 0 ? images : []);
  const [imageFile, setImageFile] = useState(null);
  const [link, setLink] = useState("");
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    /* if (imageData.length >= 3) {
      messageContext.setMessage({
        type: "error",
        text: "Solo se pueden cargar 3 banners.",
      });
      setImageFile(null);
      setLink("");
      return e.target.reset();
    } */

    if (imageFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageUrl = event.target.result;
        const newImageData = [...imageData, { img: imageUrl, link }];
        setImageData(newImageData);
        onImageDataChange(newImageData);
        // Restablecer el formulario
        setImageFile(null);
        setLink("");
      };

      reader.readAsDataURL(imageFile);
      e.target.reset();
    }
  };

  const handleLinkChange = (index, event) => {
    const updatedImageData = [...imageData];
    updatedImageData[index].link = event.target.value;
    setImageData(updatedImageData);
    onImageDataChange(updatedImageData);
  };

  const handleRemoveImage = async (index) => {
    const updatedImageData = [...imageData];
    const imageToDelete = updatedImageData[index]

    if (imageToDelete.img.includes(process.env.REACT_APP_AWS_BUCKET)) {
      // await deleteImage(imageToDelete.img)
    }

    updatedImageData.splice(index, 1);
    setImageData(updatedImageData);
    onImageDataChange(updatedImageData);
  };

  const handleImageDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleImageDragOver = (index, e) => {
    e.preventDefault();
    if (draggedIndex !== null && draggedIndex !== index) {
      const updatedImageData = [...imageData];
      const [draggedImage] = updatedImageData.splice(draggedIndex, 1);
      updatedImageData.splice(index, 0, draggedImage);
      setImageData(updatedImageData);
      setDraggedIndex(index);
      onImageDataChange(updatedImageData);
    }
  };

  return (
    <div
      className="image-drop-zone"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "1rem",
        margin: "2rem auto",
      }}
    >
      <h3 className="center" style={{ marginBottom: "1.5rem" }}>
        Cargar Banners
      </h3>
      <form onSubmit={handleFormSubmit}>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImageFile(e.target.files[0])}
        />
        <input
          type="url"
          placeholder="Ingrese un enlace URL"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <button
          type="submit"
          style={{
            padding: "0.3rem 1rem",
            marginRight: "0",
            marginLeft: "auto",
            maxWidth: "130px",
          }}
        >
          Agregar
        </button>
      </form>
      {imageFile && imageData.length < 3 && (
        <strong>Imagen cargada: {imageFile.name}</strong>
      )}
      <div className="image-list" style={{ width: "100%", maxWidth: "100%" }}>
        {imageData.map((item, index) => (
          <div
            key={index}
            className="image-item"
            draggable
            onDragStart={() => handleImageDragStart(index)}
            onDragOver={(e) => handleImageDragOver(index, e)}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              minWidth: "100%",
              height: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              padding: "1rem",
              margin: "2rem auto",
              position: "relative",
            }}
          >
            <span
              /* onClick={() => handleRemoveImage(index)} */
              style={{
                position: "absolute",
                fontSize: "1.2rem",
                left: "0.5rem",
                top: "0.5rem",
                cursor: "pointer",
                zIndex: "10",
              }}
              title="Mover"
            >
              <FontAwesomeIcon icon={faGripLines} />
            </span>
            <span
              onClick={() => handleRemoveImage(index)}
              style={{
                position: "absolute",
                fontSize: "1.2rem",
                right: "0.5rem",
                top: "0.5rem",
                cursor: "pointer",
                zIndex: "10",
              }}
              title="Eliminar"
            >
              <FontAwesomeIcon icon={faClose} />
            </span>
            <img
              src={item.img}
              alt={`Image ${index}`}
              style={{ height: "5rem" }}
            />
            <br />
            <input
              type="url"
              placeholder="Ingrese un enlace URL"
              value={item.link}
              onChange={(e) => handleLinkChange(index, e)}
              style={{
                width: "100%",
                maxWidth: "100%",
                padding: "0.4rem 0.5rem",
                fontSize: "0.8rem",
              }}
            />
            {/* <button onClick={() => handleRemoveImage(index)} style={{ padding: '0.3rem 1rem', marginRight: '0', marginLeft: 'auto', maxWidth: '130px' }}>Eliminar</button> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageDropZone;
