import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;

const ENDPOINTS = {
    aws: `${API_URL}/aws/`,
};

export const getImage = async ({ fileKey }) => {
    const query = `?fileKey=${fileKey}&bucketName=${AWS_BUCKET}`;
    const response = await axios.get(`${ENDPOINTS.aws}image${query}`);

    return response.data;
};

export const uploadImage = async (file, objectKey) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('bucketName', AWS_BUCKET);
    formData.append('objectKey', objectKey);

    const response = await axios.post(`${ENDPOINTS.aws}image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data.url;
};

export const deleteImage = async ({ objectKey }) => {
    const response = await axios.delete(`${ENDPOINTS.aws}image`, {
        data: { bucketName: AWS_BUCKET, objectKey },
    });

    return response.data;
};