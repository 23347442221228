import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import MessageContext from "../../../contexts/MessageContext";
import useApiHook from "../../../hooks/ApiHook";
import {
  businessEdit,
  businessGetById,
} from "../../../services/BusinessService";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import FormatToastMessage from "../../../shared/FormatMessage";
import ImageCircleCropperModal from "../../../shared/ImageCircleCrpperModal";
import ImageCropperModal from "../../../shared/ImageCropperModal";
import ImageDropZone from "../../../shared/ImageDropZone";
import Required from "../../../shared/Required";
import RequiredData from "../../../shared/RequiredData";
import { convertBanners, fromBase64ToBlob, onUploadImage } from "../../../shared/utils";
import {
  inRange,
  sanitizeInputNumber,
} from "../../../shared/validations/Validation";

let isLogoImageCropperModalOpen = false;
let isFaviconImageCropperModalOpen = false;
//let isHeaderImageCropperModalOpen = false;
let logoImageContent = null;
let faviconImageContent = null;
let headerImageContent = null;
let isConfirmationEditModalOpen = false;
const errorsFields = {
  name: "BUSINESS.ERROR.NAME_REQUIRED",
  rut: "BUSINESS.ERROR.RUT_REQUIRED",
  address: "BUSINESS.ERROR.ADDRESS_REQUIRED",
  commission: "BUSINESS.ERROR.COMMISSION_REQUIRED",
  paymentType: "BUSINESS.ERROR.PAYMENT_TYPE_REQUIRED",
  subdomain: "BUSINESS.ERROR.SUBDOMAIN_REQUIRED",
  description: "BUSINESS.ERROR.DESCRIPTION_REQUIRED",
  primaryColor: "BUSINESS.ERROR.PRIMARY_COLOR_REQUIRED",
  secondaryColor: "BUSINESS.ERROR.SECONDARY_COLOR_REQUIRED",
  user: "BUSINESS.ERROR.USER_REQUIRED",
  password: "BUSINESS.ERROR.PASSWORD_REQUIRED",
  repassword: "BUSINESS.ERROR.PASSWORD_CONFIRM_REQUIRED",
  logoUrl: "BUSINESS.ERROR.LOGO_REQUIRED",
};

const buttonBackStyle = {
  color: "#ff588e",
  borderColor: "#ff588e",
  display: 'flex',
  width: 'fit-content',
  margin: 'auto',
  marginBottom: '20px',
}

const BusinessEdit = ({ onEdit, step, setStep, navbar = null, goBackButton = null }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const apiHook = useApiHook();
  const { id } = useParams();
  const messageContext = useContext(MessageContext);
  const [responseBusiness, setResponseBusiness] = useState(null);
  const [stateVersion, setStateVersion] = useState(0);
  const [nameValue, setNameValue] = useState(null);
  const [rutValue, setRutValue] = useState(null);
  const [addressValue, setAddressValue] = useState(null);
  const [commissionValue, setCommissionValue] = useState(null);
  const [markupValue, setMarkupValue] = useState(null);
  const [paymentValue, setPaymentValue] = useState("0");
  const [subdomainValue, setSubdomainValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState(null);
  const [primaryColorValue, setPrimaryColorValue] = useState(null);
  const [secondaryColorValue, setSecondaryValue] = useState(null);
  const [imageData, setImageData] = useState([]);
  const logoRef = useRef();
  const faviconRef = useRef();
  //const headerRef = useRef();

  const getValuesStep1 = () => {
    return {
      name: nameValue || "required",
      rut: rutValue || "required",
      address: addressValue || "required",
      commission: commissionValue || "required",
      markup: markupValue || "required",
      businessType: 'COMPANY',
      paymentType: paymentValue !== "0" ? paymentValue : "required"
    };
  };

  const getValuesStep2 = () => {
    return {
      subdomain: subdomainValue || "required",
      description: descriptionValue || "required",
      primaryColor: primaryColorValue || "required",
      secondaryColor: secondaryColorValue || "required",
      logoUrl: logoImageContent || "required",
      faviconUrl: faviconImageContent || "required",
      headerUrl: headerImageContent || "",
      banners: imageData || [],
    }
  };

  const init = async () => {
    const response = await apiHook({
      fnApiCall: businessGetById,
      params: { id },
    });
    if (response.ok) {
      setResponseBusiness(response.data);
      setNameValue(response.data.name);
      setCommissionValue(response.data.commission);
      setMarkupValue(response.data.markup);
      setPaymentValue(response.data.paymentType);
      setSubdomainValue(response.data.subdomain);
      setDescriptionValue(response.data.description);
      setPrimaryColorValue(response.data.primaryColor);
      setSecondaryValue(response.data.secondaryColor);
      setRutValue(response.data.rut);
      setAddressValue(response.data.address);
      setImageData(response.data.banners);
      logoImageContent = response.data.logoUrl || null;
      faviconImageContent = response.data.faviconUrl || null;
      headerImageContent = response.data.headerUrl || null;
    }
    reRender();
  };

  useEffect(() => {
    init();
  }, []);

  const reRender = () => {
    setStateVersion(stateVersion + 1);
  };

  const moveToStep = (stepValue) => {
    if (stepValue < 1) {
      setStep(1);
      return navigate("/admin/business");
    }
    let errorMessages = [];

    if (stepValue === 2) {

      errorMessages = validateRequiredFields(errorMessages, getValuesStep1());
      if (errorMessages.length > 0) {
        return;
      }

      errorMessages = [];

      if (nameValue.length > 120) {
        errorMessages.push(t("BUSINESS.ERROR.NAME_MAX_LENGTH"));
      }

      if (inRange({ range: { value: commissionValue, min: 1, max: 100 } }) !== true) {
        if (
          inRange({ range: { value: commissionValue } }) === "NaN" ||
          !sanitizeInputNumber(commissionValue)
        ) {
          errorMessages.push(t("BUSINESS.ERROR.COMMISSION_NOT_NUMBER"));
        }
        else if (!inRange({ range: { value: commissionValue, min: 1, max: 100 } })) {
          errorMessages.push(t("BUSINESS.ERROR.COMMISSION_OUT_RANGE"));
        }
      }
      if (inRange({ range: { value: markupValue, min: 1, max: 100 } }) !== true) {
        if (
          inRange({ range: { value: markupValue } }) === "NaN" ||
          !sanitizeInputNumber(markupValue)
        ) {
          errorMessages.push(t("BUSINESS.ERROR.MARKUP_NOT_NUMBER"));
        }
        else if (!inRange({ range: { value: markupValue, min: 1, max: 100 } })) {
          errorMessages.push(t("BUSINESS.ERROR.MARKUP_OUT_RANGE"));
        }
      }
      errorMessages = showErrorMessages(errorMessages);
      if (errorMessages.length > 0) {
        return;
      }

      errorMessages = [];
    }

    setStep(stepValue);
    reRender();
  };

  const showLogoImageCropperModal = async () => {
    const file = logoRef.current.files[0];
    const image = await onUploadImage(file);
    logoImageContent = image;
    isLogoImageCropperModalOpen = true;
    reRender();
  };

  const showFaviconImageCropperModal = async () => {
    const file = faviconRef.current.files[0];
    const image = await onUploadImage(file);
    faviconImageContent = image;
    isFaviconImageCropperModalOpen = true;
    reRender();
  };

  /* const showHeaderImageCropperModal = async () => {
    const file = headerRef.current.files[0];
    const imageBase64 = await getBase64FromFile(file);
    headerImageContent = imageBase64;
    isHeaderImageCropperModalOpen = true;
    reRender();
  }; */

  const hideLogoImageCropperModal = () => {
    faviconRef.current.value = "";
    isLogoImageCropperModalOpen = false;
    reRender();
  };

  const hideFaviconImageCropperModal = () => {
    faviconRef.current.value = "";
    isFaviconImageCropperModalOpen = false;
    reRender();
  };
  /* const hideHeaderImageCropperModal = () => {
    headerRef.current.value = "";
    isHeaderImageCropperModalOpen = false;
    reRender();
  }; */

  const onChangeLogoImage = () => {
    showLogoImageCropperModal();
  };

  const onChangeFaviconImage = () => {
    showFaviconImageCropperModal();
  };

  /* const onChangeHeaderImage = (image) => {
    showHeaderImageCropperModal();
  }; */

  const onCropLogoImage = async (croppedImage) => {
    const resizedImage = await fromBase64ToBlob(croppedImage)
    // await deleteImage(logoImageContent)

    const image = await onUploadImage(resizedImage);
    hideLogoImageCropperModal();
    logoImageContent = image;
  };

  const onCropFaviconImage = async (croppedImage) => {
    const resizedImage = await fromBase64ToBlob(croppedImage)
    // await deleteImage(logoImageContent)

    const image = await onUploadImage(resizedImage);
    hideFaviconImageCropperModal();
    faviconImageContent = image;
  };

  /* const onCropHeaderImage = (image) => {
    hideHeaderImageCropperModal();
    headerImageContent = image;
  }; */

  const showConfirmationEditModal = () => {
    isConfirmationEditModalOpen = true;
    reRender();
  };

  const hideConfirmationEditModal = () => {
    isConfirmationEditModalOpen = false;
    reRender();
  };

  const handleImageDataChange = (newImageData) => {
    setImageData(newImageData);
  };

  const onClickAdd = async () => {
    let errorMessages = [];
    errorMessages = validateRequiredFields(errorMessages, getValuesStep2());
    if (errorMessages.length > 0) {
      return hideConfirmationEditModal()
    }

    if (errorMessages.length > 0) {
      return hideConfirmationEditModal();
    }

    errorMessages = [];

    let banners = []
    try {
      banners = await convertBanners(imageData)
    } catch (error) {
      messageContext.setMessage({
        type: "error",
        text: error.message,
      });
      return hideConfirmationEditModal();
    }

    const values2 = { ...getValuesStep2(), banners }

    const refers = {
      id: responseBusiness._id,
      ...getValuesStep1(),
      ...values2,
    };
    /* for (let key in refers) {
      if (refers[key] === responseBusiness[key] && key !== "id") {
        delete refers[key];
      }
    } */

    if (Object.keys(refers).length <= 1) {
      return (
        messageContext.setMessage({
          type: "error",
          text: t("BUSINESS.ERROR.NO_CHANGES"),
        }),
        hideConfirmationEditModal()
      );
    }
    hideConfirmationEditModal();
    const responseData = await apiHook({
      fnApiCall: businessEdit,
      params: { data: refers },
    });

    if (responseData.ok) {
      messageContext.setMessage({
        type: "success",
        text: `BUSINESS.${responseData.data.message}`,
      });
      onEdit(responseData.data.result);
    }
  };

  const validateRequiredFields = (errorMessages, refers) => {
    for (let key in refers) {
      if (refers[key] === "required") {
        errorMessages.push(t(errorsFields[key]));
      }
    }
    errorMessages = showErrorMessages(errorMessages);
    return errorMessages;
  }

  const showErrorMessages = (errorMessages) => {
    if (errorMessages.length > 0) {
      messageContext.setMessage({
        type: "error",
        text: FormatToastMessage(
          t("BUSINESS.ERROR.TITLE_ERRORS"),
          errorMessages
        ),
      });
    }
    return errorMessages;
  }

  return (
    <>
      <div
        style={{
          maxWidth: "500px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {navbar && navbar}
        <nav className="flex-start">
          {goBackButton && goBackButton}
          <h1 style={{
            margin: 0,
            marginLeft: 'var(--spacing)'
          }}>{t("BUSINESS.EDIT")}</h1>
        </nav>
        {responseBusiness ? (
          <>
            {step === 1 && (
              <>
                <label>
                  {t("BUSINESS.NAME")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.NAME")}
                    onChange={(e) => {
                      setNameValue(e.target.value);
                    }}
                    value={nameValue}
                    required
                  />
                </label>
                <label>
                  {t("BUSINESS.RUT")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.RUT")}
                    onChange={(e) => {
                      setRutValue(e.target.value);
                    }}
                    value={rutValue}
                    required
                  />
                </label>
                <label>
                  {t("BUSINESS.ADDRESS")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.ADDRESS")}
                    onChange={(e) => {
                      setAddressValue(e.target.value);
                    }}
                    value={addressValue}
                    required
                  />
                </label>
                <label>
                  {t("BUSINESS.COMMISSION")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.COMMISSION")}
                    onChange={(e) => {
                      setCommissionValue(e.target.value);
                    }}
                    value={commissionValue}
                    required
                  />
                </label>
                <label>
                  {t("BUSINESS.MARKUP")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.MARKUP")}
                    onChange={(e) => {
                      setMarkupValue(e.target.value);
                    }}
                    value={markupValue}
                    required
                  />
                </label>
                <div className="grid">
                  <div>
                    <label>
                      {t("BUSINESS.PAYMENT_TYPE")}
                      <Required />
                    </label>
                    <select
                      defaultValue={paymentValue}
                      onChange={(e) => {
                        setPaymentValue(e.target.value);
                      }}
                      required
                    >
                      <option value="1">
                        {t("BUSINESS.PAYMENT_TYPE_ACCOUNT")}
                      </option>
                      <option value="2">
                        {t("BUSINESS.PAYMENT_TYPE_ONLINE")}
                      </option>
                    </select>
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <label>
                  {t("BUSINESS.SUBDOMAIN")}
                  <Required />
                  <input
                    type="text"
                    placeholder={t("BUSINESS.SUBDOMAIN")}
                    value={subdomainValue}
                    onChange={(e) => {
                      setSubdomainValue(e.target.value);
                    }}
                    disabled={subdomainValue === 'myglobal' ? true : false}
                    required
                  />
                  {subdomainValue === 'myglobal' && (
                    <p style={{ color: 'red' }}>{t("BUSINESS.SUBDOMAIN_NOT_EDITABLE")}</p>
                  )}
                </label>
                <label>
                  {t("BUSINESS.DESCRIPTION")}
                  <Required />
                  <textarea
                    onChange={(e) => {
                      setDescriptionValue(e.target.value);
                    }}
                    placeholder={t("BUSINESS.DESCRIPTION")}
                    value={descriptionValue}
                  ></textarea>
                </label>
                <div className="grid">
                  <label>
                    {t("BUSINESS.PRIMARY_COLOR")}
                    <Required />
                    <input
                      type="color"
                      onChange={(e) => {
                        setPrimaryColorValue(e.target.value);
                      }}
                      value={primaryColorValue}
                      required
                    />
                  </label>
                  <label>
                    {t("BUSINESS.SECONDARY_COLOR")}
                    <Required />
                    <input
                      type="color"
                      onChange={(e) => {
                        setSecondaryValue(e.target.value);
                      }}
                      value={secondaryColorValue}
                      required
                    />
                  </label>
                </div>
                <div className="grid">
                  <label htmlFor="logo">
                    {t("BUSINESS.LOGO_URL")}
                    <Required />
                    <input
                      type="file"
                      id="logo"
                      ref={logoRef}
                      onChange={onChangeLogoImage}
                      name="file"
                    />
                  </label>
                  {/* <label htmlFor="header">
                    {t("BUSINESS.HEADER_URL")}
                    <Required />
                    <input
                      type="file"
                      ref={headerRef}
                      onChange={onChangeHeaderImage}
                      id="header"
                      name="file"
                    />
                  </label> */}
                </div>
                <div className="grid">
                  <div>
                    {logoImageContent ? (
                      <img src={logoImageContent} alt="logo" />
                    ) : (
                      <img src={responseBusiness.logoUrl} alt="logo" />
                    )}
                  </div>
                  <div>
                    {headerImageContent ? (
                      <img src={headerImageContent} alt="header" />
                    ) : (
                      <img
                        src={responseBusiness.headerUrl}
                        alt="logo"
                        style={{
                          display: !responseBusiness.headerUrl ? "none" : "",
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="grid">
                  <label htmlFor="favicon">
                    {t("BUSINESS.FAVICON_URL")}
                    <Required />
                    <input
                      type="file"
                      id="favicon"
                      ref={faviconRef}
                      onChange={onChangeFaviconImage}
                      name="file"
                    />
                  </label>
                </div>
                <div className="grid">
                  <div>
                    {faviconImageContent ? (
                      <img src={faviconImageContent} alt="logo" />
                    ) : (
                      <img src={responseBusiness.faviconUrl} alt="logo" />
                    )}
                  </div>
                </div>
                <ImageDropZone onImageDataChange={handleImageDataChange} images={imageData} />
              </>
            )}
            {/* {step === 3 && (
              <>
                <label>
                  {t("BUSINESS.EMAIL")}
                  <Required />
                  <input
                    type="email"
                    placeholder={t("BUSINESS.EMAIL")}
                    onChange={(e) => {
                      setUser(e.target.value);
                    }}
                    value={responseBusiness.user}
                    required
                  />
                </label>
              </>
            )} */}
            <br />
            <RequiredData />
            <div style={{}} className="grid form-end-buttons">
              {step < 2 && (
                <>
                  <div className="flex-start">
                    <button
                      className="secondary outline"
                      onClick={() => moveToStep(step - 1)}
                    >
                      {t("GO_BACK")}
                    </button>
                  </div>
                  <div className="flex-end">
                    <button
                      className="primary"
                      onClick={() => moveToStep(step + 1)}
                    >
                      {t("NEXT")}
                    </button>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="flex-start">
                    <button
                      className="secondary outline"
                      onClick={() => moveToStep(step - 1)}
                    >
                      {t("GO_BACK")}
                    </button>
                  </div>
                  <div className="flex-end">
                    <button
                      type="submit"
                      className="primary"
                      onClick={showConfirmationEditModal}
                    >
                      {t("BUSINESS.EDIT")}
                    </button>
                  </div>
                </>
              )}
            </div>
            <ImageCropperModal
              isOpen={isLogoImageCropperModalOpen}
              onCancel={hideLogoImageCropperModal}
              onCrop={(croppedImage) => {
                onCropLogoImage(croppedImage);
              }}
              imageContent={logoImageContent}
            />
            <ImageCircleCropperModal
              isOpen={isFaviconImageCropperModalOpen}
              onCancel={hideFaviconImageCropperModal}
              onCrop={(croppedImage) => {
                onCropFaviconImage(croppedImage);
              }}
              imageContent={faviconImageContent}
            />
            {/* <ImageCropperModal
              isOpen={isHeaderImageCropperModalOpen}
              onCancel={hideHeaderImageCropperModal}
              onCrop={(croppedImage) => {
                onCropHeaderImage(croppedImage);
              }}
              imageContent={headerImageContent}
              aspectRatio={16 / 4}
            /> */}
            <ConfirmationModal
              isOpen={isConfirmationEditModalOpen}
              titleKey="BUSINESS.EDITION.TITLE"
              messageKey="BUSINESS.EDITION.CONFIRM_MESSAGE"
              onConfirm={onClickAdd}
              onCancel={hideConfirmationEditModal}
            />
          </>
        ) : (
          <Link
            role="button"
            style={{ ...buttonBackStyle }}
            className="primary outline"
            to="/admin/business"
          >{t('GO_BACK')}</Link>

        )}
      </div>
    </>
  );
};

export default BusinessEdit;
